import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PadiDiveShopHeader from 'components/padi-dive-shop/dive-shop-header';
import { BEGIN_CHECKOUT } from 'helpers/constants/events';
import { useFormat } from 'helpers/hooks/useFormat';
import { getUserIdToken } from 'helpers/padi/cognito';
import { useCart } from 'frontastic';
import { useAccount } from 'frontastic/hooks';
import CartContent from './components/cart-content';
import { useTrackPageViewCart, useTrackClickBeginCheckout } from './hooks/useTrack';
import { CartProps } from './types';
import OrderSummary from '../order-summary';
import CheckoutButton from '../order-summary/components/checkout-button';
import { CheckoutButtonProps } from '../order-summary/types';

const Cart: React.FC<CartProps> = ({ paymentMethods, ...props }) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { trackPageViewCart } = useTrackPageViewCart();
  const { trackClickBeginCheckout } = useTrackClickBeginCheckout();
  const { data, isEmpty, totalItems } = useCart();
  const { loggedIn } = useAccount();
  const { 'fe-enable-mixed-cart': ldFlagEnableMixedCart } = useFlags();

  const [idToken, setIdToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isMixedCart, setIsMixedCart] = useState(false);
  const link = !loggedIn ? '/login?lvp=checkout' : '/checkout';
  const searchParams = props.searchParams;
  const projectConfig = props.projectConfig;

  useEffect(() => {
    const getIdToken = getUserIdToken();
    if (getIdToken) {
      setIdToken(getIdToken);
    }

    if (data) {
      setIsMixedCart(data?.isMixedCart ?? false);
    }
    setIsLoading(false);
  }, [data, idToken]);

  if (!isEmpty) trackPageViewCart(data ?? null);

  const handleCheckoutButton = useCallback(() => {
    trackClickBeginCheckout(data ?? null);
    //Trigger an `ESC` key click to close any active flyouts
    const event = new Event('keyup') as KeyboardEvent;
    (event.key as string) = 'Escape';
    document.dispatchEvent(event);
  }, [data, trackClickBeginCheckout]);

  const defaultCheckoutButtonProps: CheckoutButtonProps = useMemo(() => {
    return {
      text: formatCartMessage({ id: 'checkout.go', defaultMessage: 'Go to checkout' }),
      link,
      onClick: handleCheckoutButton,
      disabled: isEmpty,
      dataAnalytics: BEGIN_CHECKOUT,
    };
  }, [formatCartMessage, link, handleCheckoutButton, isEmpty]);

  return (
    <div className="relative bg-neutral-200">
      <div className="mx-auto flex max-w-7xl flex-col px-0 lg:flex-row lg:items-start lg:gap-26 lg:px-32 lg:py-48">
        <CartContent
          className="bg-white px-16 py-12 md:px-24 md:py-12 lg:w-[70%] lg:rounded-md lg:px-20 lg:py-36 xl:px-48"
          {...props}
        />
        <div className="flex flex-col items-start lg:w-[30%]">
          <OrderSummary
            className="mb-20 min-h-240 w-full bg-white px-16 pb-12 pt-24 md:px-24 md:py-12 lg:rounded-md lg:p-36 lg:px-2 lg:pb-44 xl:px-48"
            title={formatCartMessage({ id: 'order.summary', defaultMessage: 'Order Summary' })}
            includeLoginSuggestion={false}
            paymentMethods={paymentMethods}
            button={
              <CheckoutButton
                className="md:block"
                {...defaultCheckoutButtonProps}
                disabled={!ldFlagEnableMixedCart && isMixedCart}
              />
            }
            searchParams={searchParams}
            projectConfig={projectConfig}
          />
          <div className="mx-auto mb-20 w-full bg-white lg:mb-0">
            <PadiDiveShopHeader
              bgColor="white"
              textAlign="center"
              searchParams={searchParams}
              projectConfig={projectConfig}
            />
          </div>
        </div>
      </div>

      {/* <CheckoutButton
        className="sticky bottom-0 w-full border-t border-neutral-400 bg-white p-16 md:hidden"
        dataAnalytics={BEGIN_CHECKOUT}
        {...defaultCheckoutButtonProps}
      /> */}
    </div>
  );
};

export default Cart;
