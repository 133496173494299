import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { Order } from 'shared/types/cart/Order';
import { mutate } from 'swr';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { useCart, useAccount } from 'frontastic';
import ThankYouHeader from './components/thank-you-header';
import usePurchase from '../../hooks/usePurchase';
import useTrackPage from '../../hooks/useTrack';
import FeedbackIconLayer from '../../../../commercetools-ui/atoms/button/feedbackIconLayer';
import { sdk } from 'sdk';

export interface BillingAddress {
  name: string;
  billingStreetAddressLine1: string;
  billingStreetAddressLine2: string;
  billingCity: string;
  billingState: string;
  billingPostalCode: string;
  billingCountryCode: string;
}
export interface Payment {
  authCode: string;
  expirationMonth: string;
  expirationYear: string;
  last4: string;
  piNum: string;
  ccType: string;
  paymentType: string;
}
export interface StripeSession {
  billing: BillingAddress;
  payment: Payment;
  statusMessage: string;
}

const ThankYouContent: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { formatMessage } = useFormat({ name: 'thank-you' });
  const { transaction, data } = useCart();
  const { trackPage } = useTrackPage();
  const { loggedIn, getProjectConfig, account, updateAccountSession } = useAccount();
  const { getOrderByOrderNumber } = usePurchase();
  let order: Order = Object.assign({}, data, {
    cartId: data?.cartId || '',
    email: account?.email,
    sum: transaction.total,
    discountCodes: data?.discountCodes,
    taxed: data?.taxed,
  });

  const searchParams = useSearchParams();
  const orderNumber = searchParams.get('orderNumber')?.trim() || '';

  // const fetchStripeResults = async () => {
  //   if (!loggedIn || !sessionId) return;
  //   const stripeSessionId = searchParams.get('session_id')?.trim() || '';
  //   const currencyCode = searchParams.get('currency')?.trim() || '';
  //   const stripeUrl = await getProjectConfig('EXTENSION_STRIPE_BASE_URL');
  //   const response = await fetchWithIdToken(
  //     `${stripeUrl.setting}/session-status?currency=${currencyCode}&session_id=${stripeSessionId}`,
  //   );
  //   if (!response.ok) return <Redirect target="/checkout" />;
  //   const stripeSessionData = await response.json();
  //   const commerceData = await getCommerceToolsOrder(stripeSessionData?.session?.metadata?.orderNumber);
  //   await updateAccountSession();
  //   await mutate('/action/account/getAccount');
  //   return commerceData;
  // };

  // const handleDataFetching = async () => {
  //   setIsLoading(true);
  //   if (!orderNumber) {
  //     const commerceData = await fetchStripeResults();
  //     console.log('purchase data', commerceData);
  //     trackPage(commerceData as Order);
  //     triggerVwo(0);
  //   } else {
  //     const commerceData = await getCommerceToolsOrder();
  //     // Add the discountCodes (not sure why they get lost);
  //     commerceData.discountCodes = data?.discountCodes;
  //     console.log('non-stripe data', data);
  //     console.log('non-stripe purchase commerceData', commerceData);
  //     trackPage(commerceData ?? null);
  //     await mutate('/action/cart/getCart');
  //     setIsLoading(false);
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const commerceData = await getCommerceToolsOrder(orderNumber);
      commerceData.discountCodes = data?.discountCodes;
      console.log('non-stripe data', data);
      console.log('non-stripe purchase commerceData', commerceData);
      trackPage(commerceData ?? null);
      await mutate('/action/cart/getCart');
      setIsLoading(false);
    };
    fetchData();
  }, [orderNumber]);

  if (!loggedIn || !orderNumber) return <Redirect target="/" />;

  const getCommerceToolsOrder = async (stripeOrderNumber = '') => {
    order = await getOrderByOrderNumber(orderNumber || stripeOrderNumber);
    return order;
  };

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      {isLoading ? (
        <FeedbackIconLayer loading={isLoading} variant="ghost" />
      ) : (
        <div className="mx-auto max-w-7xl px-24 lg:px-32">
          <div className="mx-auto lg:mx-0 lg:max-w-none">
            <div className="bg-white lg:rounded-md lg:pt-36">
              <ThankYouHeader email={account?.email} onPrint={handlePrint} />
            </div>
            {/* {order?.lineItems && order.lineItems.length > 0 && (
                  <OrderSummary
                    className="bg-white px-16 pb-24 md:px-24 lg:mt-0 lg:block lg:min-w-[35%] lg:p-36"
                    order={order}
                    dataReference="order"
                    title={formatMessage({ id: 'order.details', defaultMessage: 'Order details' })}
                    includeItemsList
                    classNames={{
                      infoContainer: 'border-none p-0 pt-16',
                      totalAmount:
                        'md:text-18 font-medium flex items-center justify-between lg:border-t lg:border-neutral-400 lg:pt-12',
                    }}
                  />
                )} */}
          </div>
        </div>
      )}
    </>
  );
};

export default ThankYouContent;
